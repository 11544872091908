/* Ensures full height */
html, body {
  height: 100%;
  margin: 0;
}

.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* This ensures the container takes up the full viewport height */
}

.content-container {
  flex: 1; /* This makes sure it takes up all available space */
}

/* You can also add styles for your Navbar, Footer, and any other global styles here. */
