.nav-custom {
    background-color: #2E3A59; /* Dark Navy */
}

.nav-custom .navbar-brand,
.nav-custom .nav-link,
.nav-custom .dropdown-toggle {
    color: #F7FFF7; /* Off-White */
    transition: color 0.3s ease; /* Smooth transition */
}

.nav-custom .nav-link:hover,
.nav-custom .dropdown-toggle:hover {
    color: #FF6B6B; /* Salmon Pink */
}

.nav-custom .navbar-toggler-icon {
    filter: invert(1); /* Ensure the toggler is visible against the dark background */
}

.nav-dropdown-custom:hover {
    background-color: #1A535C; /* Teal Blue */
    color: #F7FFF7; /* Off-White */
}

.nav-dropdown-custom:hover .nav-link {
    color: #F7FFF7; /* Off-White */
}





/* Footer Styles - Unchanged from previous */

.footer {
    background-color: #222;
    color: #bbb;
    padding: 20px 0;
    text-align: center;
    margin-top: auto;
}

.footer p {
    margin: 0;
}
