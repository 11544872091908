/* Base Styles */
body, html {
    height: 100%;
}

.homepage {
    background-color: #F7FFF7; /* Off-White */
    color: #2E3A59; /* Dark Navy */
    font-family: 'Roboto', sans-serif; /* Roboto font from Google Fonts */
    min-height: 100%; /* Ensure the homepage takes the full viewport height */
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.homepage-header {
    background-color: #2E3A59; /* Dark Navy */
    color: #F7FFF7; /* Off-White */
    padding: 60px 0;
    text-align: center;
}

.about-section,
.newsletter-section,
.partners-section,
.cta-section {
    background-color: #F7FFF7; /* Off-White */
    color: #2E3A59; /* Dark Navy */
}

.features-section,
.prevention-section {
    background-color: #2E3A59; /* Dark Navy */
    color: #F7FFF7; /* Off-White */
    padding: 60px 0; /* Uniform padding for sections */
}

/* Button Styling */
button {
    background-color: #FF6B6B; /* Salmon Pink */
    color: #2E3A59; /* Dark Navy */
    border: none; /* Removing the default border */
    padding: 10px 20px; /* Slight padding for buttons */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Elevated effect */
    transition: all 0.3s ease; /* Smooth transition */
    cursor: pointer; /* Indicating it's clickable */
}

button:hover {
    background-color: #FF9B9B; /* Lighter pink on hover */
}

button:active {
    transform: translateY(2px); /* Slight downward movement on click */
}

/* Feature Card Styling */
.feature-card {
    border: none;
    padding: 20px;
    border-radius: 10px; /* Consistency in design */
    transition: all 0.3s ease; /* Smooth transition */
}

.feature-card-1 { background-color: #FF6B6B; } /* Salmon Pink */
.feature-card-2 { background-color: #FFE66D; } /* Yellow */
.feature-card-3 { background-color: #4ECDC4; } /* Turquoise */
.feature-card-4 { background-color: #1A535C; } /* Dark Teal */

.feature-card:hover {
    transform: scale(1.1); /* Enlarge on hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Elevated effect */
}

/* Tip Card Styling */
.tip-card {
    border: none;
    overflow: hidden; /* Clip the content outside of box */
}

.tip-card img {
    height: 200px;
    width: 100%; /* Ensures image takes full width */
    object-fit: cover; /* Fit images to card size */
    transition: all 0.3s ease; /* Smooth transition */
}

.tip-card:hover img {
    transform: scale(1.1); /* Zoom on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .homepage-header, .features-section, .prevention-section {
        padding: 40px 0;
    }
    
    button {
        padding: 8px 16px;
    }
}

/* Add more media queries as necessary based on specific breakpoints you want to target. */
