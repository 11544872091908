.malware-list-container {
    display: flex;
    flex-wrap: wrap;  /* Allow the items to wrap to next line if the container width isn't enough */
    justify-content: space-between; /* Distribute cards evenly */
    gap: 20px;  /* Gap between the cards */
}

.malware-item {
    background-color: #2E3A59; /* Dark Navy background for the card */
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    flex: 0 1 calc(50% - 10px);  /* Each card takes up roughly 50% of the container's width minus half the gap width. */
}

.malware-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.malware-item h3 {
    color: #FF6B6B; /* Salmon Pink for the title */
    margin-bottom: 10px;
}

.malware-item p {
    color: #FFFFFF; /* White text for better readability on dark background */
    margin-bottom: 10px;
}

.malware-options {
    display: flex;
    gap: 10px;
}

.malware-options a {
    background-color: #FF6B6B; /* Salmon Pink */
    color: #2E3A59; /* Dark Navy */
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.malware-options a:hover {
    background-color: #FF9B9B; /* Lighter pink on hover */
}


.prevention-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.prevention-item {
    padding: 20px;
    margin: 10px 0;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    transition: transform 0.3s;
}

.prevention-item:hover {
    transform: translateY(-5px);
}


.guide-page {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 5px;
}

.guide-item {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}

.guide-item h3 {
    font-weight: bold;
}

.guide-item:not(:last-child) {
    margin-bottom: 20px;
}
